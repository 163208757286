<template>
  <main id="content" role="main">
    <section class="report_group">
      <section class="report_box">
        <div class="contest-apply step1">
          <div class="step">
            <ul>
              <li :class="{ on : index===0 }" v-for="(item, index) in competitionItems">{{ item.name }}</li>
              <li>지원서확인</li>
            </ul>
          </div>
          <div :key="`FF${keyIndex}`">
            <template v-for="(item, idx) in competitionItems" v-if="idx === currentTabId" >
              <div class="title">{{ item.name }}</div>
              <div class="form">
                <form name="">
                  <table>
                    <tbody>
                      <template v-for="(info, infoIndex) in item.infos">
                        <tr v-if="info.type == 1 && info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <ValidationProvider :rules="`requiredMsg:${info.name}`" v-slot="{ errors }">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <div class="radio-container">
                                <label :for="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`">
                                  <input 
                                    v-model="competitionItems[currentTabId].infos[infoIndex].inputs[0].answer" 
                                    type="radio" 
                                    :id="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`" 
                                    :name="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}`" 
                                    :value="input.id">
                                  <span class="radio-bg"></span>
                                  <span class="text">{{ input.name }}</span>
                                </label>
                              </div>
                            </template>
                            <span class="input-error-desc">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr v-if="info.type == 1 && !info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <div class="radio-container">
                                <label :for="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`">
                                  <input 
                                    v-model="competitionItems[currentTabId].infos[infoIndex].inputs[0].answer" 
                                    type="radio" 
                                    :id="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`" 
                                    :name="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}`" 
                                    :value="input.id">
                                  <span class="radio-bg"></span>
                                  <span class="text">{{ input.name }}</span>
                                </label>
                              </div>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 2 && info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <ValidationProvider :name="info.name" :field="info.name" :rules="`requiredChkMsg:${info.name}, CK-${infoIndex}, ${info.option}`" v-slot="{ errors }">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <BaseCheckbox 
                                :name="`CK-${infoIndex}`"
                                v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" 
                                :trueValue="1"
                                >
                                {{ input.name }}
                              </BaseCheckbox>
                            </template>
                            <span class="input-error-desc">{{ errors[0] }}</span>
                            </ValidationProvider>
                          </td>
                        </tr>
                        <tr v-if="info.type == 2 && !info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <BaseCheckbox 
                                :name="`CK-${infoIndex}`"
                                v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" 
                                :trueValue="1"
                                >
                                {{ input.name }}
                              </BaseCheckbox>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 3 && info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <div>
                              <ValidationProvider :rules="`requiredMsg:${input.name}`" v-slot="{ errors }">
                                <BaseInputElement
                                  v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" 
                                  :name="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`"
                                  :placeholder="input.name"
                                  maxlength="300"
                                />
                                <span class="input-error-desc">{{ errors[0] }}</span>
                              </ValidationProvider>
                              </div>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 3 && !info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <div>
                                <!-- <BaseLabelElement type="inline" :label="input.name"/> -->
                                <!-- <BaseInputElement
                                  v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" 
                                  :name="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`"
                                  :width="input.name?'half':'full'"
                                  :placeholder="info.option"
                                  maxlength="300" -->
                                <BaseInputElement
                                  v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" 
                                  :name="`INPUT${input.competitionItemId}_${input.competitionItemInfoId}_${input.id}`"
                                  :placeholder="input.name"
                                  maxlength="300"
                                />
                              </div>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 4 && info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <ValidationProvider :rules="`requiredMsg:${input.name}`" v-slot="{ errors }">
                              <input type="hidden" v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" />
                              <FileInput
                                v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].fileName"
                                :name="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                :id="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                @change="uploadEtcInfoFile"
                                @remove="removeEtcInfoFile"
                                :accept="info.option"
                              />
                              <span class="input-error-desc">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 4 && !info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <input type="hidden" v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" />
                              <FileInput
                                v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].fileName"
                                :name="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                :id="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                @change="uploadEtcInfoFile"
                                @remove="removeEtcInfoFile"
                                :accept="info.option"
                              />
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 5 && info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <ValidationProvider :rules="`requiredMsg:${input.name}`" v-slot="{ errors }">
                              <input type="hidden" v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" />
                              <FileInput
                                v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].fileName"
                                :placeholder="input.name"
                                :name="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                :id="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                @change="uploadEtcInfoFile"
                                @remove="removeEtcInfoFile"
                                :accept="info.option"
                              />
                              <span class="input-error-desc">{{ errors[0] }}</span>
                              </ValidationProvider>
                            </template>
                          </td>
                        </tr>
                        <tr v-if="info.type == 5 && !info.isRequisiteItem">
                          <th>{{info.name}}</th>
                          <td class="form-row">
                            <template v-for="(input, inputIndex) in info.inputs">
                              <input type="hidden" v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].answer" />
                              <FileInput
                                v-model="competitionItems[currentTabId].infos[infoIndex].inputs[inputIndex].fileName"
                                :placeholder="input.name"
                                :name="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                :id="`F${currentTabId}_${infoIndex}_${inputIndex}`"
                                @change="uploadEtcInfoFile"
                                @remove="removeEtcInfoFile"
                                :accept="info.option"
                              />
                            </template>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                  <div class="text-center btn-wrap">
                    <BackButton @click="onBack"/>
                    <BaseButton type="line" @click="save(true)">{{$t('content.common.button.btn01')}}</BaseButton>
                    <BaseButton v-if="currentTabId > 0" type="line" @click="doPrev">{{$t('content.common.button.btn03')}}</BaseButton>
                    <BaseButton v-if='isShowNextBtn' @click="doNext">{{$t('content.common.button.btn02')}}</BaseButton>
                    <BaseButton v-if='!isShowNextBtn' :disabled="!isPledge" @click="save()">{{$t('content.accelerating.academy.Common.txt01')}}</BaseButton>
                  </div>
                </form>
              </div>
            </template>
          </div>
        </div>
      </section>
    </section>
  </main>
</template>

<script>

import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import ProgramsApplicationService from '../programs/application/service/ProgramsApplicationService'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import BaseLabelElement from '@/components/base/BaseLabelElement.vue';
import BaseInputElement from '@/components/base/BaseInputElement.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseRadioButton from '@/components/base/BaseRadioButton.vue';
import FileInput from '@/components/base/FileInput.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import { FilePaths } from '@/common/portalApi';
import BackButton from '@/components/base/BackButton.vue';
import { getSystemErrorMap } from 'util';
import {
  required
} from 'vee-validate/dist/rules';

extend('requiredMsg', {
  ...required,
  params: ['name'],
  validate(val, { name }) {
    console.log('size', val, name)
    if (!val) {
      if (name) {
        return `${name}을 입력해 주세요.`
      } else {
        return `입력해 주세요.`
      }
    }
    else return true
  },
});
extend('requiredChkMsg', {
  ...required,
  params: ['name','id','size'],
  validate(val, { name, id, size }) {
    console.log(name, id, size)
    if (document.querySelectorAll(`input[name=${id}]:checked`).length == 0) {
      return `${name}을 입력해 주세요.`
    }
    if (size) {
      if (document.querySelectorAll(`input[name=${id}]:checked`).length > size) {
        return `${size}개 이하로 선택하세요`
      }
    }
    return true
  },
});

export default {
  name: 'ContestApplyStep1',
  mixins: [mixinHelperUtils],
  components: {
    ValidationProvider,
    ValidationObserver,
    BaseLabelElement,
    BaseInputElement,
    BaseCheckbox,
    BaseRadioButton,
    FileInput,
    BaseButton,
    BackButton
  },
  data() {
    // return {
    //   example: '항목명노출',
    //   example2: '항목명노출2',
    // };
    return {
      accPgmMngNo: 96,
      example: '항목명노출',
      example2: '항목명노출2',
      keyIndex: 0,
      /**
       * Acc지원프로그램 관리 상세
       * @type {import('../dto/AccApplyPgmMngDetailDto').default}
       */
      accApplyPgmInfo: undefined,
      accApplyPgmMngDetail: null,
      competitionItems: [],
      /**
       * Acc지원 임시저장 정보
       * @type {AccApplyTemporaryDto}
       */
      accApplyTemporary: undefined,
      /**
       * Acc지원 정보
       * @type {AccApplyDto}
       */
      accApply: true,

      accInfo: {
      },
      userInfo: null,
      tmpIndex: null,
      currentTabId: 0,
      preview: [],
      isPledge: false
    };
  },
  async created() {
    this.userInfo = this.$store.getters.getUserInfo();

    // 등록 항목을 가져온다.
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);
    this.accApplyPgmMngDetail = await this.programsApplicationService.getAccApplyPgmMngDetail(this.accPgmMngNo);
    console.log('accApplyPgmMngDetail', this.accApplyPgmMngDetail)
    if (this.accApplyPgmMngDetail && this.accApplyPgmMngDetail.id) {
      if (this.isNotApplicable) {
        this.showAlert(
          // 현재 '공모전명'에 지원이 불가능합니다.
          this.$t(
            'content.accelerating.Common.txt26',
            [this.accApplyPgmMngDetail.title],
          ),
          () => {
            this.$router.replace({
              name: 'Home',
            });
            // this.$router.go(-1);
            // this.onBack();
          },
        );
        return;
      }

      const tmp = await this.programsApplicationService.getTempAccApplyMember(this.accPgmMngNo, this.userInfo.id);
      console.log('tmp', tmp)
      if (tmp) {
        if (tmp.isState === 1) { // 737: 이미 지원한 내역이 있습니다.
          this.showAlert(
            // 기존에 지원완료된 데이터가 있습니다. 중복지원은 불가능합니다.
            this.$t('content.accelerating.academy.ApplyComplete.txt05'),
            () => {
              // 공모 지원 내역으로 이동
              this.$router.replace({
                name: 'ProgramsMyApplyList',
              });
            },
          );
          return;
        }
        if (tmp.isState === 2) {
          this.accApplyTemporary = []
          this.tmpIndex = null
        } else {
          this.accApplyTemporary = tmp.items
          this.tmpIndex = tmp.id
        }
      } else {
        this.accApplyTemporary = []
        this.tmpIndex = null
      }

      console.log('getTempAccApplyMember', this.accApplyTemporary)
      if (this.accApplyTemporary && this.accApplyTemporary.length > 0) {
        
        // 임시저장 정보가 있다면
        this.showConfirm(
          // 임시 저장된 지원서 내용을 불러오시겠습니까?
          this.$t('content.accelerating.academy.ApplyTemporary.txt01'),
          {
            buttons: [
              // 취소 버튼
              {
                title: this.$t('content.common.button.btn05'),
                type: 'line',
                class: '',
                handler: () => {
                  this.onBack();
                  // this.$router.push({
                  //   name: 'Home',
                  // });
                },
              },
              // 새로 작성 버튼
              {
                title: this.$t('content.accelerating.academy.ApplyTemporary.txt04'),
                type: 'line',
                class: '',
                handler: () => {
                  console.log('new')
                  this.accApplyTemporary = []
                },
              },
              // 불러오기 버튼
              {
                title: this.$t('content.common.button.btn11'),
                class: '',
                handler: async () => {
                  this.setAnswer();
                },
              },
            ],
          },
        );
        

      }  
    }
    
    this.competitionItems = await this.programsApplicationService.getAccApplyPgmMngItemList(this.accPgmMngNo);
    console.log(competitionItems, this.accPgmMngNo)
    if (this.competitionItems.length > 0) {
      this.currentTabId = 0;
      this.preview = this.competitionItems[0];
    }
    document.addEventListener('keydown', this.prohibiteRefresh);
  },
  destroyed() {
    document.removeEventListener('keydown', this.prohibiteRefresh);
  },
  methods: {
    onBack() {
      this.showConfirm(
        this.$t('content.accelerating.Common.txt28'),
        () => {
          // 저장된 페이지 확인
          const previousUrl = localStorage.getItem('applyPreviousUrl');
          if (previousUrl) {
            const data = JSON.parse(previousUrl);
            document.location.href = data.path;
            return;
          }
          // 공모 지원하기 목록 (해당 탭)으로 이동
          this.$router.replace({
            name: 'ProgramsApplicationListTab',
            params: {
              accPgmMngNoString: `${this.accPgmMngNo}`,
            },
          });
        },
      );
    },
    async save(isTmp = false) {
      let   response = []
      for (const item of this.competitionItems) {
        for (const info of item.infos) {
          if (info.type == 1) {
            response.push( {competitionId: info.competitionId, 
                        competitionItemId: info.competitionItemId, 
                        competitionItemInfoId: info.id, 
                        id: 0, 
                        memberId: this.userInfo.id,
                        answer: info.inputs[0].answer
                    });
          } else if (info.type == 2) {
            for (const input of info.inputs) {
              response.push( {competitionId: input.competitionId, 
                          competitionItemId: input.competitionItemId, 
                          competitionItemInfoId: input.competitionItemInfoId, 
                          id: input.id,
                          memberId: this.userInfo.id,
                          answer: input.answer
                      });
            }
          } else if (info.type == 3) {
            for (const input of info.inputs) {
              response.push( {competitionId: input.competitionId, 
                          competitionItemId: input.competitionItemId, 
                          competitionItemInfoId: input.competitionItemInfoId, 
                          id: input.id,
                          memberId: this.userInfo.id,
                          answer: input.answer
                      });
            }
          } else if (info.type == 4) {
            for (const input of info.inputs) {
              response.push( {competitionId: input.competitionId, 
                          competitionItemId: input.competitionItemId, 
                          competitionItemInfoId: input.competitionItemInfoId, 
                          id: input.id,
                          memberId: this.userInfo.id,
                          answer: input.answer
                      });
            }
          }
        }
      }

      const formData = {};
      formData.id = this.tmpIndex;
      formData.competitionId = this.accPgmMngNo;
      formData.memberId = this.userInfo.id;
      formData.items = response;
      formData.isState = isTmp ? 0:1
      
      if (isTmp) {
        const res = this.portalApiClient({
          url: `/api2/competition/response`,
          data: formData
        }).then(({ data, status }) => {
          console.log('save', data.data.id)
          if (data.result) {
            this.tmpIndex = data.data.id
            if (status === 200) {
              // 임시저장되었습니다.
              this.showAlert(this.$t('content.accelerating.alert.txt02'));
            } else {
              this.showAlert(`데이터저장 중 오류가 발생했습니다.<br>(오류코드: ${status} )`);
            }
          }
        });
      } else {
        this.showConfirm(`'${this.accApplyPgmMngDetail?.title}'에 지원하시겠습니까?`, () => {
          const res = this.portalApiClient({
            url: `/api2/competition/response`,
            data: formData
          }).then(({ data }) => {
            if (data.result) {
              ///programs/application/19/apply/complete
              this.$router.push({name: 'ProgramsApplyCompleted'});
            }
          });
        });
      }
    },
    doPrev() {
      document.querySelector(`.step-wrap li:nth-of-type(${this.currentTabId+1})`).classList.remove('on')
      this.preview = this.competitionItems[--this.currentTabId]
      
      // ++this.keyIndex;
    },
    setErrorText(id, txt) {
      document.querySelector(id).innerHTML = txt
    },
    async doNext() {
      this.validateForm(this.$refs.form).then(() => {
        this.$refs.form.reset();

        console.log('doNext GOOD')
        ++this.currentTabId;
        if (this.currentTabId >= this.competitionItems.length) {
          this.preview = [];
        } else {
          this.preview = this.competitionItems[this.currentTabId]
        }
        console.log('currentTabId', this.currentTabId)
        document.querySelector(`.step-wrap li:nth-of-type(${this.currentTabId+1})`).classList.add('on')
      });
    },
    async ddddd() {
      let checkList = []
      let idx = 0, inputIndex = 0, isValid = true
      var errorTxts = document.querySelectorAll('.input-error-desc');
      errorTxts.forEach(function (error){
        error.innerHTML = "";
      });

      for (const info of this.preview.infos) {
        if (info.type == 1) {
          console.log("INPUT", info.inputs[0].answer)
          if (info.isRequisiteItem && !info.inputs[0].answer) {
            //this.showAlert(`[${info.name}]필수 입력입니다.`);
            // return;
            // this.setErrorText(`#PP-${idx}`, `[${info.name}] 필수 입력입니다.`)
            isValid = false
          }
        } else if (info.type == 2) {
          checkList = []
          for (const input of info.inputs) {
            if (input.answer) {
              checkList.push(input.answer)
            }
          }
          if (info.isRequisiteItem && checkList.length === 0) {
            this.showAlert(`[${info.name}] 필수 입력입니다.`);
            return;
          } else if (info.option) {
            if (checkList.length > info.option*1) {
              this.showAlert(`[${info.name}]는 ${info.option}개 이하로 선택입니다.`);
              return;
            }
          }
        } else if (info.type == 3) {
          inputIndex = 0
          for (const input of info.inputs) {
            if (info.isRequisiteItem && !input.answer) {
              this.setErrorText(`#PP-${idx}-${inputIndex}`, `[${info.name}] 필수 입력입니다.`)
              isValid = false
              // if (input.name) {
              //   this.showAlert(`[${info.name}][${input.name}] 필수 입력입니다.`);
              // } else {
              //   this.showAlert(`[${info.name}] 필수 입력입니다.`);
              // }
              // return;
            }
            inputIndex++;
          }
        } else if (info.type == 4) {
          for (const input of info.inputs) {
            if (info.isRequisiteItem && !input.answer) {
              this.showAlert(`[${info.name}] 필수 입력입니다.`);
              return;
            }
          }
        }
        ++idx;
      }

      if (isValid) {
        ++this.currentTabId;
        if (this.currentTabId >= this.competitionItems.length) {
          this.preview = [];
        } else {
          this.preview = this.competitionItems[this.currentTabId]
        }
        console.log('currentTabId', this.currentTabId)
        document.querySelector(`.step-wrap li:nth-of-type(${this.currentTabId+1})`).classList.add('on')

      }
      // ++this.keyIndex;
    },
    /**
     * 기타입력 첨부파일 업로드
     */
    uploadEtcInfoFile(file, id) {
      const ids = id.slice(1, id.length).split("_")
      this.uploadFile(22, 0, this.userInfo.id, file)
        .then((data) => {
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = file.name
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = data.id
          ++this.keyIndex;
        })
        .catch(() => {
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = null
          this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = null
          ++this.keyIndex;
        });
    },
    /**
     * 기타입력 첨부파일 삭제
     */
    removeEtcInfoFile(file, id) {
      const ids = id.slice(1, id.length).split("_")
      this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].fileName = null
      this.competitionItems[ids[0]*1].infos[ids[1]*1].inputs[ids[2]*1].answer = null
      ++this.keyIndex;
    },

    
    setAnswer() {
      if (this.accApplyTemporary.length > 0) {
        for (const item of this.competitionItems) {
          for (let info of item.infos) {
            if (info.type == 1) {
              info.inputs[0].answer = this.findRadioAnswer(info.competitionId, info.competitionItemId, info.id, 0)
            } else if (info.type == 2) {
              for (let input of info.inputs) {
                input.answer = this.findRadioAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
              }
            } else if (info.type == 3) {
              for (let input of info.inputs) {
                input.answer = this.findAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
              }
            } else if (info.type == 4) {
              for (let input of info.inputs) {
                const tmp = this.findFileAnswer(input.competitionId, input.competitionItemId, input.competitionItemInfoId, input.id)
                if (tmp) {
                  input.fileName = tmp.fileName
                  input.answer = tmp.answer
                }
              }
            }
          }
        }
        ++this.keyIndex;
      }
    },
    findCheckAnswer2(idx, infoIdx) {
      console.log('findCheckAnswer2(competitionId, competitionItemId, competitionItemInfoId, id)')
      const inputs = this.competitionItems[idx].infos[infoIdx];
      console.log(inputs)
      const answers = []
      for (const tmp of inputs.inputs) {
        if (tmp.answer === 1) {
          answers.push( tmp.name )
        }
      }
      return answers.join(" / ");
    },
    findRadioAnswer2(idx, infoIdx, id) {
      console.log('findRadioAnswer2(competitionId, competitionItemId, competitionItemInfoId, id)', idx, infoIdx, id)
      const inputs = this.competitionItems[idx].infos[infoIdx];
      for (const tmp of inputs.inputs) {
        if (tmp.id === id) {
          return tmp.name;
        }
      }
      return null;
    },
    findRadioAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          if (tmp.answer) return tmp.answer*1;
        }
      }
      return null;
    },
    findAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          console.log('findAnswer', tmp.answer)
          if (tmp.answer) return  tmp.answer;
        }
      }
      return null;
    },
    findFileAnswer(competitionId, competitionItemId, competitionItemInfoId, id) {
      // console.log('findAnswer(competitionId, competitionItemId, competitionItemInfoId, id)', competitionId, competitionItemId, competitionItemInfoId, id)
      for (const tmp of this.accApplyTemporary) {
        if (tmp.competitionId === competitionId && tmp.competitionItemId === competitionItemId && tmp.competitionItemInfoId == competitionItemInfoId && tmp.id === id) {
          console.log('findAnswer', tmp.answer)
          if (tmp.answer) return  {answer: tmp.answer, fileName: tmp.fileName};
        }
      }
      return null;
    },
     /**
     * 새로고침 방지
     */
    prohibiteRefresh(e) {
      alert( e.keyCode )
      if (e.keyCode === 116) { // F5키
        e.preventDefault();
      }
    }
  }
};
</script>

<style>
.checkbox-container {
  display: inline-block !important;
  margin-right: 80px !important;
  margin-top: 5px !important;
}
.error {
  border-color: #ff5013 !important; 
}
select {
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 1.57 !important;
  padding: 11px 15px !important;
}
.input-border, .upload-name {
  border: 1px solid #eee !important;
  border-radius: 5px !important;
  width: 100% !important;
  height: 43px !important;
  background: #fff !important;
  padding: 10px 15px !important;
  font-size: 12px !important;
  font-weight: 300 !important;
}
.input-file-container label {
  height: 43px !important;
  line-height: 43px !important;
  margin-left: 5px !important;
  font-size: 14px !important;
  flex-basis: 150px !important;
  border-radius: 5px !important;
  text-align: center !important;
  background: #000 !important;
  color: #fff !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
}

</style>
